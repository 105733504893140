:root {
  --cool-blue: rgb(100, 136, 234);
}

body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  overflow: hidden;

  color: white;
  font-family: sans-serif;
}

/* <CSS RESET> */
button, input {
  border: none;
  font-family: inherit;
  color: inherit;
}
button {
  cursor: pointer;
}
/* </CSS RESET> */

#canvas {
  position: absolute;
  z-index: 0;
}

.hud {
  position: absolute;
  z-index: 1;

  margin: 0.5rem;
}

#toolConfig {
  position: absolute;
  bottom: 0;
  right: 0;
}

#toolbar {
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  >.tool {
    --border-size: 0.2rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-style: solid;
    border-radius: 0.2rem;
    border-color: white;
    border-width: var(--border-size);
    padding: calc(0.5rem - var(--border-size));
    padding-left: calc(0.75rem - var(--border-size));
    padding-right: calc(0.75rem - var(--border-size));
    box-sizing: border-box;

    background-color: black;

    &:not(.selected):hover {
      background-color: var(--cool-blue);
    }

    // TODO: Replace with something else
    &:focus {
      outline: none;
    }

    &.selected {
      cursor: default;

      --border-size: 0.3rem;
      border-color: var(--cool-blue);
    }
  }
}
