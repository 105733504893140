:root {
  --cool-blue: #6488ea;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  font-family: sans-serif;
  overflow: hidden;
}

button, input {
  color: inherit;
  border: none;
  font-family: inherit;
}

button {
  cursor: pointer;
}

#canvas {
  z-index: 0;
  position: absolute;
}

.hud {
  z-index: 1;
  margin: .5rem;
  position: absolute;
}

#toolConfig {
  position: absolute;
  bottom: 0;
  right: 0;
}

#toolbar {
  flex-direction: row;
  gap: .5rem;
  display: flex;
  right: 0;
}

#toolbar > .tool {
  border-style: solid;
  border-color: #fff;
  border-width: var(--border-size);
  padding: calc(.5rem - var(--border-size));
  padding-left: calc(.75rem - var(--border-size));
  padding-right: calc(.75rem - var(--border-size));
  box-sizing: border-box;
  --border-size: .2rem;
  background-color: #000;
  border-radius: .2rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

#toolbar > .tool:not(.selected):hover {
  background-color: var(--cool-blue);
}

#toolbar > .tool:focus {
  outline: none;
}

#toolbar > .tool.selected {
  cursor: default;
  border-color: var(--cool-blue);
  --border-size: .3rem;
}
/*# sourceMappingURL=index.2fdf0dd3.css.map */
